import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../containers/MainLayout'
import { editCase, getCaseDetails } from '../../apis/cases'
import { uploadMedia } from '../../apis/media';
import CaseForm from '../../components/CaseForm';
import moment from 'moment';
import { mapCaseStatus } from '../../utils/caseStatus';
import { capitalizeFirstLetter } from '../../utils';
import { Link } from 'react-router-dom';
import { useCommonState } from "../../stores/common";

const EditCase = () => {

    const navigate = useNavigate()
    const { id } = useParams()

    const [caseData, setCaseData] = useState(null)

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const commonStore = useCommonState()

    useEffect(() => {

        if (commonStore?.currentRole !== '') {
            const _getCaseDetails = async () => {

                const _case = await getCaseDetails({ id: id, current_role: commonStore?.currentRole })

                if (_case.success) {
                    setCaseData(_case.data)
                }
                else {
                    navigate('/no-permission/')
                }
            }
            _getCaseDetails()
        }

    }, [commonStore?.currentRole])


    const uploadFiles = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        return await uploadMedia(formData)
    }


    /**
     * Action only when all datas are v
     * @param {array} data 
     */
    const onSubmit = async (params) => {
        console.log(`onSubmit called`)
        if (isLoading) {
            return
        }

        setIsLoading(true);

        let additionalArgs = {}
        try {
            if (params?.documents && params?.documents?.length > 0 && (!params.documents[0]['_id'] || !caseData?.documents)) {
                let documents = await uploadFiles(params.documents[0])
                if (documents.success) {
                    additionalArgs.documents = [documents.data._id.toString()]
                }
                else {
                    setIsLoading(false);
                    return Swal.fire('', 'Could not upload media. Please try again!', 'error');
                }
            }
        } catch (e) {
            console.error(e);
        }
        try {
            if (params?.photos && params?.photos?.length > 0 && (!params.photos[0]['_id'] || !caseData?.photos)) {
                let photos = await uploadFiles(params.photos[0])
                if (photos.success) {
                    additionalArgs.photos = [photos.data._id.toString()]
                }
                else {
                    setIsLoading(false);
                    return Swal.fire('', 'Could not upload media. Please try again!', 'error')
                }
            }
        } catch (e) {
            console.error(e);
        }
        const { documents, photos, ...filterParams } = params
        const result = await editCase(id, {
            ...filterParams,
            ...additionalArgs
        })
        if (result.success) {
            setIsSubmitted(true);
        }
        else {
            Swal.fire('', result.message, 'error');
        }
    }

    return (
        <>
            <MainLayout>
                <div className="ncc-pos-relative">
                    {!isSubmitted ?
                        <div className="container pb-5">
                            <h1 className="mb-5">Case Details</h1>
                            <div className="row">
                                <div className="col-md-9">
                                    <CaseForm caseData={caseData} loading={isLoading} onSubmit={onSubmit} viewAction='edit'/>
                                </div>

                                <div className="col-md-3">
                                    <div className="case-notes-wrapper border p-2">
                                        <div className="d-flex">
                                            <h5>Status: &nbsp;</h5>
                                            <h5 className="text-center ml-3">{mapCaseStatus(caseData?.status)}</h5>
                                        </div>
                                        <h5>Case Notes</h5>
                                        <ul className="list-group">
                                            {caseData && caseData?.trackings.length > 0 && caseData?.trackings.map((tracking, i) => {
                                                return (
                                                    <li key={i} className="list-group-item">
                                                        {moment(tracking.createdAt).format('LLL')} <br />
                                                        Level of Action: {tracking.rating}<br />
                                                        <strong>{capitalizeFirstLetter(tracking.approve_type)} by {tracking.approve_user.fullName}</strong>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="case-submitted pb-5">
                            <h3 className="mb-3">Changes saved</h3>
                            <p>The changes are successfully updated.</p>

                            <Link
                                to={'/'}
                                className="btn btn-success"
                            >
                                Return to Home
                            </Link>
                        </div>}
                </div>
            </MainLayout>
        </>
    )
}

export default EditCase