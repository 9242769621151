import apiConfig, {
    getRequestHeaders
} from './base'

const { axiosInstance } = apiConfig

const baseApiURL = apiConfig.baseApiURL

/** 
 * Get all cases with pagination 
 **/
export const getAllCategoryLevels = async (args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders();
    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/general/category-levels/?case_status=${args?.case_status}&case_id=${args?.case_id}`, requestHeaders);
        result = data;
    }
    catch (e) {
        result.message = e.message
    }

    return result;
}

/** 
 * Get all cases category 
 **/
export const getAllCaseCat = async (args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/general/case-category/?case_status=${args?.case_status}`, requestHeaders);
        result = data;
    }
    catch (e) {
        result.message = e.message;
    }

    return result;
}