import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import FormEditor from "../Fields/Editor";
import { getAllCategoryLevels, getAllCaseCat } from "../../apis/categoryLevels";
import { getAllMinistries } from "../../apis/ministries";
import { getAllCountryCodes } from "../../apis/countryCodes"
import { getAllReviewPeriods } from "../../apis/reviewPeriods";
import { mobileLookup } from "../../apis/contacts";
import ModalApprove from "../Modals/ApproveModal";
import ModalEndorse from "../Modals/EndorseModal";
import ModalReject from "../Modals/RejectModal";
import ModalDischarge from "../Modals/DischargeModal";
import ModalExtend from "../Modals/ExtendModal";
import CalendarSvg from "./CalendarSvg";
import { Link, useNavigate } from "react-router-dom";
import { hasPermissions } from "../../utils";
import {
	NCInput,
	NCSelect,
	NCDatePicker,
	NCFileInput
} from "../Fields"
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { useCommonState } from "../../stores/common";
import Swal from "sweetalert2";
import { editCase, disChargeCase, extendCase, checkDuplicatedPhone, deleteCase } from "../../apis/cases";
import { getActiveMinistry } from "../../sso";
import { uploadMedia } from "../../apis/media";
import _, { set } from "lodash";
import { getAllLocalUsersByMinistryId } from "../../apis/localUsers";

const Loading = ({ loading }) => {

	if (loading) {
		return (
			<Spinner size="sm" variant="info" animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		)
	}
	return ''
}

const CaseForm = ({ onSubmit, loading, caseData, viewOnly = false, createNewCase = false, isInReview = false, viewAction = 'view' }) => {

	const navigate = useNavigate()

	const commonStore = useCommonState()

	const formMethods = useForm()

	const {
		register,
		control,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors, isDirty, touchedFields },
	} = formMethods;

	const requiredOptions = {
		required: {
			value: true,
			message: "This field is required",
		},
	};

	const notRequiredOptions = {
		required: {
			value: false,
			message: "This field is not required",
		},
	};

	const [categoryLevelsList, setCategoryLevelsList] = useState([]);
	const [caseCatList, setCaseCatList] = useState([]);
	const [ministriesList, setMinistriesList] = useState([]);
	const [contactActiveMinistriesList, setContactActiveMinistriesList] = useState([]);
	const [countryCodes, setCountryCodes] = useState([]);
	const [reviewPeriods, setReviewPeriods] = useState([]);
	const [isSearchAvailable, setIsSearchAvailable] = useState(false);
	const [isSearching, setIsSearching] = useState(false)
	const [currentContact, setCurrentContact] = useState()
	const [caseRatingObject, setCaseRatingObject] = useState(null)
	// const [ministryId, setMinistryId] = useState()

	const [showReject, setShowReject] = useState(false)
	const [showApprove, setShowApprove] = useState(false)
	const [showEndorse, setShowEndorse] = useState(false)

	const [canSubmit, setCanSubmit] = useState(false)
	const [canEdit, setCanEdit] = useState(false)
	const [isDisabled, setIsDisabled] = useState(true)
	const [canEditApprovalRemark, setCanEditApprovalRemark] = useState(false)
	const [canEditAdmimRemark, setCanEditAdmimRemark] = useState(false)
	const [isEmptyApprovalRemark, setIsEmptyApprovalRemark] = useState(true)
	const [isEmptyAdminRemark, setIsEmptyAdminRemark] = useState(true)
	const [isAllowToDoActions, setIsAllowToDoActions] = useState(false)
	const [ctaLoading, setCTALoading] = useState(false)
	const [reviewPeriodDisable, setReviewPeriodDisable] = useState(true)
	// const [canEditReviewRemark, setCanEditReviewRemark] = useState(false)
	const [showDischarge, setShowDischarge] = useState(false)
	// const [isDisabledReviewRemark, setIsDisabledReviewRemark] = useState(true)
	const [isDisabledExtendCTA, setIsDisabledExtendCTA] = useState(true)
	const [isDisabledDischargeCTA, setIsDisabledDischargeCTA] = useState(true)
	const [showExtend, setShowExtend] = useState(false)
	const [showTable, setShowTable] = useState(false)
	const [allRemarksList, setAllRemarksList] = useState({})
	const [maxRemarkLength, setMaxRemarkLength] = useState(0)
	const [showDischargeButton, setShowDischargeButton] = useState(false)
	const [showReviewSections, setShowReviewSections] = useState(false)
	// const [showExtendRemarkArea, setShowExtendRemarkArea] = useState(false)
	const [reviewRemarkContentChanged, setReviewRemarkContentChanged] = useState('')
	// const [errorDupPhone, setErrorDupPhone] = useState(false)
	// const [isShowingAlert, setIsShowingAlert] = useState(false)
	// const [queuedAlert, setQueuedAlert] = useState([])
	const [approversList, setApproversList] = useState([]);

	// const queuedAlert = useRef([])

	// const setQueuedAlert = (value) => {
	// 	queuedAlert.current = [...queuedAlert.current, value]
	// }

	/**
	 * #43809 - Submit / View / Edit case detail - Active ministries
	 */
	useEffect(() => {
		_fetchActiveMinistries()
	}, [currentContact, commonStore?.ssoTokens])

	/**
	 * #43809 - Submit / View / Edit case detail - Active ministries
	 */
	const _fetchActiveMinistries = async () => {
		if (currentContact?.UserId && commonStore?.ssoTokens) {
			const data = await getActiveMinistry(currentContact?.UserId, commonStore?.ssoTokens?.access_token)
			setContactActiveMinistriesList(data?.Ministries);
		} else {
			setContactActiveMinistriesList([])
		}
	}


	useEffect(() => {
		if (hasPermissions(commonStore.permissions, ['Submit']) && !caseData) {
			setCanSubmit(true)
		} else {
			setCanSubmit(false)
		}
		// if (hasPermissions(commonStore.permissions, ['Edit']) && caseData) {
		// 	setCanEdit(true)
		// }
		if ((caseData?.status === 'submitted' && caseData?.created_by?.userId === commonStore?.currentUserProfile?.userId)
			|| (commonStore.currentRole === 'Admin' && (
				caseData?.status === 'submitted'
				// #43718 - Case status Approved logic update
				||
				caseData?.status === 'approved'
			))
		) {
			if (viewOnly === true) {
				setCanEdit(false)
			}
			else {
				setCanEdit(true)
			}
		}
	}, [commonStore.permissions, caseData])

	useEffect(() => {
		if (commonStore?.currentRole) {
			if (viewOnly !== true) {
				if (commonStore.currentRole === 'Approver') {
					setCanEditApprovalRemark(true)
				}
				else {
					if (commonStore.currentRole === 'Admin') {
						setCanEditAdmimRemark(true)
						//only when in Edit mode
						if (viewAction === 'edit') {
							setCanEditApprovalRemark(true)
						}
					}
				}
			}
			else {
				setCanEditAdmimRemark(false)
				setCanEditApprovalRemark(false)
			}
		}
		// setCanEditApprovalRemark( true )
	}, [commonStore.currentRole])

	useEffect(() => {
		// if (caseData && commonStore?.currentRole) {
		// 	if (!caseData?.rating && commonStore?.currentRole === 'Admin') {
		// 		setShowDischargeButton(true)
		// 	} else {
		// 		if (commonStore?.currentRole !== 'Admin') {
		// 			setShowDischargeButton(true)
		// 		} else {
		// 			setShowDischargeButton(false)
		// 		}
		// 	}
		// }
	}, [caseData, commonStore.currentRole])

	useEffect(() => {
		// console.log(`caseData?.rating `, caseRatingObject)
		if (caseData && caseRatingObject?.use_for_discharged === true) {
			setShowReviewSections(true)
			// setCanEditReviewRemark(true)
		} else {
			setShowReviewSections(false)
		}

		if (caseData && caseData?.status === "discharged") {
			setShowReviewSections(false)
		} else if (caseData && (caseData?.status === "approved" || caseData?.status === 'submitted')) {
			if (caseData?.status === 'submitted' && caseRatingObject?.use_for_discharged === true) {
				setShowDischargeButton(true) // to test
				// setShowReviewSections(true) // To test
				// setIsDisabledReviewRemark(false)
				setIsDisabledDischargeCTA(false)
				setIsDisabledExtendCTA(true)
			} else {
				setShowDischargeButton(false)
				// if (caseData?.status === 'endorsed') {
				// 	setShowDischargeButton(false)
				// } else {
				// 	setShowDischargeButton(true)
				// }
				// if (caseData?.review_remark.length > 0) {
				// 	setShowReviewSections(true)
				// } else {
				// 	setShowReviewSections(false)
				// }
				setShowReviewSections(false)
				// setCanEditReviewRemark(true)
			}
		} else {
			setShowReviewSections(false)
		}
	}, [caseData, isInReview])

	useEffect(() => {
		if (canEdit || canSubmit) {
			setIsDisabled(false)
			setReviewPeriodDisable(false)
		} else {
			// if (caseData && caseData.status === 'approved' && viewAction !== 'edit') {
			// 	setIsDisabled(false)
			// } else {
			// 	setIsDisabled(true)
			// }
			setIsDisabled(true)
			setReviewPeriodDisable(true)
		}
	}, [canEdit, canSubmit])

	useEffect(() => {

		let _ratingId, _ministryId;
		if (caseData) {

			if (caseData?.approval_remark) {
				setIsEmptyApprovalRemark(!(caseData.approval_remark.length > 0));
			} else {
				setIsEmptyApprovalRemark(false);
			}

			if (caseData?.admin_remark) {
				setIsEmptyAdminRemark(!(caseData.admin_remark.length > 0));
			}
			else {
				setIsEmptyAdminRemark(false);
			}

			try {
				const rating = JSON.parse(caseData?.rating);
				setCaseRatingObject(rating);
				_ratingId = rating?.id;
				// console.log(`_ratingId `, rating);
			}
			catch (err) {
				console.error('Error parsing rating, ', caseData?.rating)
			}

			try {
				const ministry = JSON.parse(caseData?.ministry);
				_ministryId = ministry?.id;
			}
			catch (err) {
				console.error('Error parsing ministry')
			}
		}

		setValue('ministry_id', caseData?.ministry_id);
		if (caseData?.ministry_id) {
			_onChangeCaseMinistry(caseData?.ministry_id, caseData?.approver_id);
		}
		setValue('full_name', caseData?.full_name)
		setValue('contact_number_country_code', caseData?.contact_number_country_code)
		setValue('contact_number', caseData?.contact_number)
		setValue('description', caseData?.description)
		setValue('christian_name', caseData?.christian_name)
		setValue('additional_christian_name', caseData?.additional_christian_name)
		setValue('submission_date', moment(caseData?.createdAt).toDate())
		setValue('case_title', caseData?.case_title)
		setValue('review_period', caseData?.review_period)
		setValue('review_date', moment(caseData?.review_date).toDate())
		setValue('rating', _ratingId)
		setValue('photos', caseData?.photos)
		setValue('documents', caseData?.documents)
		setValue('approval_remark', caseData?.approval_remark[caseData?.admin_remark.length - 1]?.remark_content);
		// setValue('admin_remark', caseData?.admin_remark[caseData?.admin_remark.length - 1]?.remark_content);
		setValue('review_remark', caseData?.review_remark[caseData?.review_remark.length - 1]?.remark_content);
		// setMinistryId(_ministryId);
	}, [caseData]);

	useEffect(() => {
		// "approver_id"
		// if (caseData?.approver_id && approversList.length === 1) {
		// 	setValue('approver_id', caseData?.approver_id);
		// }
	}, [caseData, approversList])

	useEffect(() => {
		if (caseData) {
			if (caseData?.review_remark.length === 1 && !caseData?.admin_remark && !caseData?.approval_remark) {
				setShowTable(false);
			}
			if (caseData?.approval_remark.length > 0 || caseData?.admin_remark.length > 0 || caseData?.review_remark.length > 1) {
				setShowTable(true);
			} else {
				setShowTable(false);
			}
			if (caseData?.approval_remark.length > 0) {
				setAllRemarksList(prevState => ({
					...prevState,
					approval_remark: caseData?.approval_remark
				}))
			}
			// if (caseData?.admin_remark.length > 0) {
			// 	setAllRemarksList(prevState => ({
			// 		...prevState,
			// 		admin_remark: caseData?.admin_remark
			// 	}))
			// }
			if (caseData?.review_remark.length > 0) {
				setAllRemarksList(prevState => ({
					...prevState,
					review_remark: caseData?.review_remark
				}))
			}
		}

	}, [caseData])


	useEffect(() => {

		let maxLength = 0;

		for (const key in allRemarksList) {
			if (allRemarksList.hasOwnProperty(key)) {
				const list = allRemarksList[key];
				if (list.length > maxLength) {
					maxLength = list.length;
				}
			}
		}
		setMaxRemarkLength(maxLength)

	}, [allRemarksList])


	useEffect(() => {
		if (caseCatList && caseData?.case_category) {
			const caseCat = caseCatList.find(cat => cat.label === caseData?.case_category)
			if (caseCat) {
				setValue('case_category', caseCat?.value)
			}
		}
	}, [caseCatList, caseData])

	useEffect(() => {
		if (viewAction !== 'create') {
			if (caseData) {
				_getCategoryLevels();
				_getMinistries();
				_getAllCountryCodes();
				_getReviewPeriods();
				_getCaseCategory();
			}
		} else {
			_getCategoryLevels();
			_getMinistries();
			_getAllCountryCodes();
			_getReviewPeriods();
			_getCaseCategory();
		}
	}, [viewAction, caseData]);

	useEffect(() => {
		if (isInReview === true) {
			setReviewPeriodDisable(false)
			// setCanEditReviewRemark(true)
			setCanEditAdmimRemark(false)
			// setShowExtendRemarkArea(true)
		} else {
			// setShowExtendRemarkArea(false)
		}
	}, [isInReview])

	useEffect(() => {
		const countryCode = getValues('contact_number_country_code')
		const phoneNumber = getValues('contact_number')

		if (countryCode && phoneNumber) {
			setIsSearchAvailable(true)
		}
		else {
			setIsSearchAvailable(false)
		}

	}, [commonStore.formChanged]);

	useEffect(() => {
		if (caseRatingObject) {
			// console.log(`caseRatingObject useeffect `, caseRatingObject);
			_postCheckingExtendDischargeCTAStatus(reviewRemarkContentChanged);
		}
	}, [caseRatingObject, reviewRemarkContentChanged])

	const _getCategoryLevels = async () => {
		let categoriesLevel = []
		const catLevels = await getAllCategoryLevels({ case_status: caseData?.status, case_id: caseData?._id });
		if (catLevels.success) {
			const filtered = filterActiveItems(catLevels.data)
			categoriesLevel = filtered.map((i) => {
				return { value: i.id, label: i.value, desc: i.description, use_for_discharged: i.use_for_discharged };
			})
		}

		if (categoriesLevel.length <= 0 && caseData) {
			try {
				const cat = JSON.parse(caseData?.rating)
				categoriesLevel.push({ value: cat.id, label: cat.value, use_for_discharged: cat.use_for_discharged })
			}
			catch (err) {
				console.warn('Error parsing ministry', err)
			}
		}

		setCategoryLevelsList(categoriesLevel)
	};

	const _getCaseCategory = async () => {
		let caseCat = []
		const caseCategory = await getAllCaseCat({ case_status: caseData?.status });
		if (caseCategory.success) {
			const filtered = filterActiveItems(caseCategory.data)
			caseCat = filtered.map((i) => {
				return { value: i.id, label: i.value, desc: i.description };
			})
		}

		if (caseCat.length <= 0 && caseData) {
			try {
				const cat = JSON.parse(caseData?.rating)
				caseCat.push({ value: cat.id, label: cat.value })
			}
			catch (err) {
				console.warn('Error parsing ministry', err)
			}
		}

		setCaseCatList(caseCat)
	};

	const handleChangeApprovalRemark = (data) => {
		if (data) {
			setIsEmptyApprovalRemark(false)
			_postCheckingExtendDischargeCTAStatus(data)
		}
		else {
			setIsEmptyApprovalRemark(true)
			_postCheckingExtendDischargeCTAStatus(null)
		}
	}

	// const handleChangeAdminRemark = (data) => {
	// 	if (data) {
	// 		setIsEmptyAdminRemark(false)
	// 	}
	// 	else {
	// 		setIsEmptyAdminRemark(true)
	// 	}
	// }

	/**
	 * #48076 - NRC-179 - [Enhancement] - Review case
	 * When level 0 is selected → Enable Discharge, Disable Extend button.
	 * When other level is selected → Enable Extend button, Disable Discharge button	
	 * @param {*} data 
	 */
	const _postCheckingExtendDischargeCTAStatus = (_review_content) => {
		if (_review_content && (viewAction == 'review' || viewAction == 'edit')) {
			if (caseRatingObject?.use_for_discharged === true) {
				setIsDisabledDischargeCTA(false);
				setIsDisabledExtendCTA(true);
			} else {
				setIsDisabledDischargeCTA(true);
				setIsDisabledExtendCTA(false);
			}
		}
		else {
			setIsDisabledDischargeCTA(true);
			setIsDisabledExtendCTA(true);
		}
	}

	const handleChangeReviewRemark = (data) => {
		setReviewRemarkContentChanged(data);
	}

	useEffect(() => {
		if (commonStore?.currentRole) {
			if (commonStore.currentRole === 'Admin') {
				if (isEmptyAdminRemark === true) {
					setIsAllowToDoActions(false)
				} else {
					setIsAllowToDoActions(true)
				}
				if (isEmptyApprovalRemark === true) {
					setIsAllowToDoActions(false)
				}
				else {
					setIsAllowToDoActions(true)
				}
			}
			else {
				if (commonStore.currentRole === 'Approver') {
					if (isEmptyApprovalRemark === true) {
						setIsAllowToDoActions(false)
					}
					else {
						setIsAllowToDoActions(true)
					}
				}
				else {
					if (commonStore?.currentRole === 'Requestor') {
						setIsAllowToDoActions(true)
					}
				}
			}

		}
	}, [isEmptyAdminRemark, isEmptyApprovalRemark, commonStore?.currentRole])

	useEffect(() => {
		if (isInReview && caseData?.review_date && caseData?.review_period) {
			let reviewDateWhenInReview = moment(moment.now()).add(caseData?.review_period, 'M').toDate();
			setValue('review_date', reviewDateWhenInReview)
		}
	}, [caseData, isInReview])

	const handleChangeReviewPeriod = (e) => {
		let month_value = e;
		var newDateObj = moment(moment.now()).add(month_value, 'M').toDate();
		setValue('review_date', newDateObj)
		// setIsDisabledReviewRemark(false)
	}

	const _getMinistries = async () => {
		let ministriesList = []

		const ministries = await getAllMinistries();
		if (ministries.success) {
			const filtered = filterActiveItems(ministries.data)
			ministriesList = filtered.map((i) => {
				return { value: i.id, label: i.value };
			})
		}

		if (ministriesList.length <= 0 && caseData) {
			try {
				const ministry = JSON.parse(caseData?.ministry)
				ministriesList.push({ value: ministry.id, label: ministry.value })
			}
			catch (err) {
				console.warn('Error parsing ministry', err)
			}
		}
		setMinistriesList(ministriesList)
	};

	/**
	 * #48072 - NRC-175 - [Enhancement] - Submit case
	 * @param {*} val 
	 */
	const _onChangeCaseMinistry = async (val, case_default_approver_id) => {
		const _users = await getAllLocalUsersByMinistryId(val);
		if (_users.success) {
			setApproversList(
				_users?.data?.map((i) => {
					return { value: i.userId, label: `${i.fullName}` };
				})
			);
			if (Array.isArray(_users?.data) && _users?.data.length === 1) {
				setValue('approver_id', _users?.data[0]?.userId)
			} else {
				if (case_default_approver_id) {
					setValue('approver_id', case_default_approver_id);
				} else {
					setValue('approver_id', null);
				}
			}
		} else {
			setValue('approver_id', null);
		}
	}

	const _getAllCountryCodes = async () => {
		const countryCodes = await getAllCountryCodes();
		if (countryCodes.success) {
			const filtered = filterActiveItems(countryCodes.data)
			setCountryCodes(
				filtered.map((i) => {
					return { value: i.id, label: `${i.value} (+${i.id})` };
				})
			);
		} else {
			setCountryCodes([]);
		}
	}

	const _getReviewPeriods = async () => {
		const periods = await getAllReviewPeriods();
		if (periods.success) {
			setReviewPeriods(
				periods.data.map((i) => {
					return { value: i.value, label: i.description };
				})
			);
		} else {
			setReviewPeriods([]);
		}
	};

	const filterActiveItems = (items) => {
		return items.filter(i => i.isActive === true)
	}

	const uploadFiles = async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await uploadMedia(formData)
	}

	const doSubmit = async (data, action, caseData) => {
		data.contact = currentContact;

		// if (data?.admin_remark) {
		// 	data = {
		// 		...data, admin_remark: [
		// 			...caseData?.admin_remark,
		// 			{
		// 				remark_content: data?.admin_remark,
		// 				updatedByUserId: commonStore?.currentUserProfile?.userId,
		// 				updatedByUserName: commonStore?.currentUserProfile?.fullName,
		// 				updatedAt: moment().format()
		// 			}
		// 		]
		// 	}
		// }

		if (data?.approval_remark) {
			data = {
				...data, approval_remark:
					[
						...caseData?.approval_remark,
						{
							remark_content: data?.approval_remark,
							updatedByUserId: commonStore?.currentUserProfile?.userId,
							updatedByUserName: commonStore?.currentUserProfile?.fullName,
							updatedAt: moment().format()
						}
					]
			}
		}

		if (data?.case_category) {
			// find in caseCatList where item in caseCatList has id === data.case_category and replace data.case_category with value of that item

			const caseCat = caseCatList.find(item => item.value === data.case_category)
			if (caseCat) {
				data.case_category = caseCat?.label
			}

		}

		if (action && (action === 'submit' || action === 'save')) {
			if (action === 'submit') {
				let emptyReViewRemark = {
					updatedByUserId: commonStore?.currentUserProfile?.userId,
					updatedByUserName: commonStore?.currentUserProfile?.fullName,
					updatedAt: moment().format(),
					remark_content: '',
				}
				data.review_remark = [emptyReViewRemark]
			}
			onSubmit(data);
		}
		else {
			handleCaseAction(action);
		}
	};


	const handleCaseAction = async (action) => {
		const data = getValues();
		setCTALoading(true);
		let updateParams;
		if (action === 'save') {
			updateParams = data;
		}
		else {
			if (commonStore?.currentRole === 'Approver') {
				updateParams = {
					approver_id: data?.approver_id,
					approval_remark: data?.approval_remark,
					status: action
				};
			}
			else {
				if (commonStore?.currentRole === 'Admin') {
					updateParams = { ...data, status: action }
				};
			}
		}

		// if (action === 'rejected') {
		// 	setRejectLoading(true)
		// 	setTimeout(() => {
		// 		setRejectLoading(false)
		// 	}, 1000)
		// }

		//Photo check upload new 
		// try {
		// 	console.log(`handleCaseAction data?.photos `, data?.photos)
		// 	console.log(`handleCaseAction data?.photos[0]['_id'] `, data?.photos[0]['_id'])
		// 	console.log(`handleCaseAction data?.photos[0]?._id `, data?.photos[0]?._id)
		// } catch (e) {

		// }
		if (data?.photos && data?.photos.length > 0 && data?.photos[0]['_id']) {
			//photos is not update
			//remove from updateParams
			try {
				delete updateParams['photos']
			}
			catch (err) {
				console.log('cannot delele', err)
			}
		}
		else {
			//Case: user update new photos
			//Start upload photos
			const photosUpload = await uploadFiles(data?.photos?.[0])
			if (photosUpload && !photosUpload.success) {
				return Swal.fire('', 'Could not upload photo. Please try again!', 'error')
			}
			//Append new photos upload to params 
			updateParams = { ...updateParams, photos: photosUpload ? [photosUpload.data._id.toString()] : [] }
		}

		//Document check upload new
		if (data?.documents && data?.documents.length > 0 && data?.documents[0]['_id']) {
			//documents is not update
			//remove from updateParams
			try {
				delete updateParams['documents']
			}
			catch (err) {
				console.log('cannot delele', err)
			}
		}
		else {
			//Case:  user update new documents
			//Start upload documents
			if (data?.documents && data?.documents[0]) {
				const documentUploads = await uploadFiles(data?.documents[0])
				if (documentUploads && !documentUploads.success) {
					setCTALoading(false);
					return Swal.fire('', 'Could not upload documents. Please try again!', 'error')
				}
				//Append new documents upload to params 
				updateParams = { ...updateParams, documents: documentUploads ? [documentUploads.data._id.toString()] : [] }
			}
		}
		if (updateParams) {
			if (updateParams?.case_category) {
				// find in caseCatList where item in caseCatList has id === data.case_category and replace data.case_category with value of that item

				const caseCat = caseCatList.find(item => item.value === updateParams.case_category)
				if (caseCat) {
					updateParams.case_category = caseCat?.label
				}

			}

			// if (data?.admin_remark && canEditAdmimRemark) {
			// 	updateParams = {
			// 		...updateParams, admin_remark:
			// 			[
			// 				...caseData?.admin_remark,
			// 				{
			// 					remark_content: data?.admin_remark,
			// 					updatedByUserId: commonStore?.currentUserProfile?.userId,
			// 					updatedByUserName: commonStore?.currentUserProfile?.fullName,
			// 					updatedAt: moment().format()
			// 				}
			// 			]
			// 	}
			// } else {
			// 	updateParams = {
			// 		...updateParams, admin_remark: caseData?.admin_remark
			// 	}
			// }

			if (data?.approval_remark && canEditApprovalRemark) {
				updateParams = {
					...updateParams, approval_remark:
						[
							...caseData?.approval_remark,
							{
								remark_content: data?.approval_remark,
								updatedByUserId: commonStore?.currentUserProfile?.userId,
								updatedByUserName: commonStore?.currentUserProfile?.fullName,
								updatedAt: moment().format()
							}
						]
				}
			} else {
				updateParams = {
					...updateParams, approval_remark: caseData?.approval_remark
				}
			}

			const result = await editCase(caseData?._id, updateParams)
			setCTALoading(false);
			if (result.success) {
				switch (action) {
					case 'endorsed':
						setShowEndorse(true);
						break;
					case 'rejected':
						setShowReject(true);
						break;
					case 'approved':
						setShowApprove(true);
						break;
				}
				setTimeout(() => {
					navigate('/')
				}, 3000);
			}
			else {
				let message = `Could not ${action} case. Please try again later!`;
				Swal.fire('', message, 'error');
			}
		}
	}

	const searchContact = async (evt) => {
		evt.preventDefault()

		if (isSearching) {
			return
		}
		setIsSearching(true)

		const countryCode = getValues('contact_number_country_code')
		const phoneNumber = getValues('contact_number')

		if (process.env.REACT_APP_FAKE_ACCOUNT) {
			try {
				const contact = JSON.parse(process.env.REACT_APP_FAKE_ACCOUNT)
				setCurrentContact(contact)
				setValue('full_name', contact.FullName)
			}
			catch (error) {
				console.log('=JSON-parse-ERROR=', error)
			}
		}
		else {

			// const contact = await mobileLookup({
			// 	mobile: phoneNumber,
			// 	countryCode: `+${countryCode}`
			// })

			let chker = await checkDuplicatedPhone(countryCode, phoneNumber)
			if (chker && chker?.success) {
				const contact = await mobileLookup({
					mobile: phoneNumber,
					countryCode: `+${countryCode}`
				})
				if (contact?.success === true) {
					setCurrentContact(contact.data)
					setValue('full_name', contact?.data?.FullName)
				} else {
					if (chker?.is_dupplicatated === true && chker?.full_name) {
						setCurrentContact(null)
						setValue('full_name', chker?.full_name)
					} else {
						setCurrentContact(null)
						setValue('full_name', '')
					}
				}
				if (contact?.success === false && chker?.is_dupplicatated) {
					let message = { title: '', type: 'warning', message: '<p>This mobile number has an existing record.</p><p>Contact not found in Noah.</p>' }
					let result = await Swal.fire({
						icon: message.type,
						title: message.title,
						html: message.message,
						showCancelButton: true,
						confirmButtonText: 'Continue',
					}).then((value) => {
						return value
					});
					//if cancel then go back
					if (result.isDismissed) {
						setTimeout(() => {
							navigate('/')
						}, 1000)
					}
				}
				else if (chker?.is_dupplicatated) {
					let message = { title: '', type: 'warning', message: '<p>This mobile number has an existing record.</p>' }
					let result = await Swal.fire({
						icon: message.type,
						title: message.title,
						html: message.message,
						showCancelButton: true,
						confirmButtonText: 'Continue',
					}).then((value) => {
						return value
					})
					//if cancel then go back
					if (result.isDismissed) {
						setTimeout(() => {
							navigate('/')
						}, 1000)
					}
				} else if (contact?.success === false) {
					let message = { title: '', type: 'warning', message: '<p>Contact not found in Noah.</p>' }
					let result = await Swal.fire({
						icon: message.type,
						title: message.title,
						html: message.message,
					}).then((value) => {
						return value
					})
				} else {
					// setCurrentContact(contact.data)
					// setValue('full_name', contact?.data?.FullName)
				}
			}
		}

		setIsSearching(false);
	}

	const handleFocusReviewDate = () => {
		document.getElementsByName('review_date')[0].focus()
	}

	// const approveCase = async (evt) => {
	// 	evt.preventDefault()
	// 	const status = await updateCaseStatus(caseData?._id, 'approved')
	// 	if (status.success) {
	// 		setShowApprove(true)
	// 		setTimeout(() => {
	// 			navigate('/')
	// 		}, 3000)
	// 	}
	// 	else {
	// 		Swal.fire('', 'Could not approve case. Please try again later!', 'error')
	// 	}
	// }

	const resetContact = () => {
		setCurrentContact(null)
		setValue('full_name', '')
	}

	const handleExtendDischarge = async (action) => {
		let data = getValues()
		let updateParams;

		if (caseData) {
			updateParams = { ...data, status: caseData?.status }
		}
		if (data?.photos && data?.photos.length > 0 && data?.photos[0]['_id']) {
			//photos is not update
			//remove from updateParams
			try {
				delete updateParams['photos']
			}
			catch (err) {
				console.log('cannot delele', err)
			}
		}
		else {
			//Case: user update new photos
			//Start upload photos
			const photosUpload = await uploadFiles(data?.photos?.[0])
			if (photosUpload && !photosUpload.success) {
				return Swal.fire('', 'Could not upload photo. Please try again!', 'error')
			}
			//Append new photos upload to params 
			updateParams = { ...updateParams, photos: photosUpload ? [photosUpload.data._id.toString()] : [] }
		}

		if (updateParams?.case_category) {
			// find in caseCatList where item in caseCatList has id === data.case_category and replace data.case_category with value of that item

			const caseCat = caseCatList.find(item => item.value === updateParams.case_category)
			if (caseCat) {
				updateParams.case_category = caseCat?.label
			}

		}

		if (data?.admin_remark && canEditAdmimRemark) {
			updateParams = {
				...updateParams, admin_remark:
					[
						...caseData?.admin_remark,
						{
							remark_content: data?.admin_remark,
							updatedByUserId: commonStore?.currentUserProfile?.userId,
							updatedByUserName: commonStore?.currentUserProfile?.fullName,
							updatedAt: moment().format()
						}
					]
			}
		} else {
			updateParams = {
				...updateParams, admin_remark: caseData?.admin_remark
			}
		}

		if (data?.approval_remark && canEditApprovalRemark) {
			updateParams = {
				...updateParams, approval_remark:
					[
						...caseData?.approval_remark,
						{
							remark_content: data?.approval_remark,
							updatedByUserId: commonStore?.currentUserProfile?.userId,
							updatedByUserName: commonStore?.currentUserProfile?.fullName,
							updatedAt: moment().format()
						}
					]
			}
		} else {
			updateParams = {
				...updateParams, approval_remark: caseData?.approval_remark
			}
		}

		if (commonStore?.currentRole) {
			updateParams = { ...updateParams, current_role: commonStore?.currentRole }
		}

		if (action === 'discharge') {
			//#48076 - NRC-179 - [Enhancement] - Review case
			updateParams = { ...updateParams };

			if (updateParams?.review_date && caseData?.review_date) {
				updateParams = { ...updateParams, review_date: caseData?.review_date };
			}

			if (commonStore?.currentRole === 'Requestor' && data?.review_remark) {
				updateParams = {
					...updateParams, review_remark:
						[
							...caseData?.review_remark,
							{
								remark_content: data?.review_remark,
								updatedByUserId: commonStore?.currentUserProfile?.userId,
								updatedByUserName: commonStore?.currentUserProfile?.fullName,
								updatedAt: moment().format()
							}
						]
				}

			} else if (data?.review_remark) {
				updateParams = {
					...updateParams, review_remark: caseData?.review_remark
				}
			}

			const result = await disChargeCase(caseData?._id, updateParams);

			if (result && result.success) {
				setShowDischarge(true)
				setTimeout(() => {
					navigate('/')
				}, 3000)
			} else if (result?.message) {
				Swal.fire('', result?.message, 'error');
			}
		} else if (action === 'extend') {

			if (data?.review_remark) {
				updateParams = {
					...updateParams, review_remark:
						[
							...caseData?.review_remark,
							{
								remark_content: data?.review_remark,
								updatedByUserId: commonStore?.currentUserProfile?.userId,
								updatedByUserName: commonStore?.currentUserProfile?.fullName,
								updatedAt: moment().format()
							}
						]
				}
			}

			const result = await extendCase(caseData?._id, updateParams)
			// console.log(`ssss `, result)
			if (result && result.success) {
				setShowExtend(true)
				setTimeout(() => {
					navigate('/')
				}, 3000)
			} else if (result?.message) {
				Swal.fire('', result?.message, 'error');
			}
		}
	}

	/**
	 * #48073 - NRC-176 - [Enhancement] - Edit case
	 */
	const handleDeleteCase = async () => {
		// console.log(`handleDeleteCase called`)
		let message = { title: '', type: 'warning', message: '<p>This action cannot be undone. Are you sure you want to delete this case?</p>' }
		let result = await Swal.fire({
			icon: message.type,
			title: message.title,
			html: message.message,
			showCancelButton: true,
			cancelButtonText: 'No',
			confirmButtonText: 'Yes',
		}).then((value) => {
			return value
		});
		//if cancel then go back
		if (result.isConfirmed) {
			//do delete case
			const result = await deleteCase(caseData?._id);
			if (result && result.success) {
				// setShowExtend(true)
				setTimeout(() => {
					navigate('/')
				}, 1000)
			} else if (result?.message) {
				Swal.fire('', result?.message, 'error');
			}
		}
	}

	const _handleOnChangeRating = (_selectedValue) => {
		// console.log(`_handleOnChangeRating `, _selectedValue);
		const _selectedRating = categoryLevelsList?.find((i) => i.value === _selectedValue);
		// console.log(`_handleOnChangeRating `, _selectedRating);
		setCaseRatingObject(_selectedRating);
	}
	// formstate has the errors
	console.log(errors);
	return (
		<form
			// onSubmit={handleSubmit(doSubmit)}
			// method="POST"
			className="g-3 needs-validation"
			noValidate
		>
			<div className="case-form-wrapper">
				{/* Section */}
				<div className="section border-bottom ncc-pb-2">
					<div className={`row ncc-mrb-px-30`}>
						<div className="col-md-2">
							<span className="span-text">Contact Number</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<div className="row">
								<div className="col-md-3">
									<NCSelect
										options={countryCodes}
										name={`contact_number_country_code`}
										isClearable={true}
										isSearchable={true}
										isMulti={false}
										requiredOptions={requiredOptions}
										control={control}
										errors={errors}
										isDisabled={isDisabled}
										onChange={resetContact}
									// onBlur={() => _checkDupPhone()}
									/>
								</div>
								<div className="col-md-6">
									<NCInput
										name={`contact_number`}
										required={requiredOptions.required}
										control={control}
										errors={errors}
										register={register}
										className={`ncc-pl-px-10`}
										isDisabled={isDisabled}
										onChange={resetContact}
									// onBlur={() => _checkDupPhone()}
									/>
									{/* {errorDupPhone && <span className="text-danger">This mobile number has an existing record.</span>} */}
								</div>
								{!caseData &&
									<div className="col-md-3">
										<button onClick={searchContact} className={`btn btn-success w-100 ${isSearchAvailable ? '' : 'disabled'}`}>
											Search in Noah &nbsp;
											{isSearching &&
												<Spinner size="sm" variant="info" animation="border" role="status">
													<span className="visually-hidden">Loading...</span>
												</Spinner>
											}
										</button>
									</div>}
							</div>
						</div>

					</div>
					<div className="mb-5 row">
						<div className="col-md-2">
							<span className="span-text">Full Name</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<NCInput
								name={`full_name`}
								required={requiredOptions.required}
								errors={errors}
								control={control}
								register={register}
								className={`ncc-pl-px-10`}
								isDisabled={currentContact ? true : isDisabled}
							/>
						</div>
					</div>
					{contactActiveMinistriesList?.length > 0 &&
						<div className="mb-5 row">
							<div className="col-md-2">
							</div>
							<div className="col-md-10 ncc-pr-0">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th width="33%" className="text-align-center">Ministry Name</th>
											<th width="33%" className="text-align-center">Role Name</th>
											<th width="33%" className="text-align-center">Start Date</th>
										</tr>
									</thead>
									<tbody>
										{contactActiveMinistriesList.map((ministryData, i) => {
											return (
												<tr key={_.uniqueId('k_')} className={``}>
													<td className="text-align-center">{ministryData?.MinistryName}</td>
													<td className="text-align-center">{ministryData?.RoleName}</td>
													<td className="text-align-center">{moment(ministryData?.StartDate).format("lll")}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					}
					{/* <div className="row">
						<div className="col-md-2">
							<span className="span-text">
								Christian Name <br /> (if any)
							</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<div className="mb-3 col-md-12">
								<NCInput
									name={`christian_name`}
									required={false}
									control={control}
									errors={errors}
									register={register}
									className={`ncc-pl-px-10`}
									isDisabled={currentContact ? true : isDisabled}
								/>
							</div>
						</div>
					</div> */}
				</div>
				{/* End Section */}

				{/* Section */}
				<div className="pb-5 section border-bottom">
					{/* Ministry field */}
					<div className="mb-5 row">
						<div className="col-md-2">
							<span className="span-text">Ministry</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<NCSelect
								options={ministriesList}
								name={`ministry_id`}
								isClearable={true}
								isSearchable={true}
								isMulti={false}
								requiredOptions={requiredOptions}
								control={control}
								errors={errors}
								isDisabled={isDisabled}
								onChange={_onChangeCaseMinistry}
							/>
						</div>
					</div>

					{/* Case Category field */}
					<div className="mb-5 row ">
						<div className="col-md-2">
							<span className="span-text">Category</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<NCSelect
								options={caseCatList}
								name={`case_category`}
								isClearable={true}
								isSearchable={true}
								isMulti={false}
								requiredOptions={requiredOptions}
								control={control}
								errors={errors}
								showDescription={true}
								isDisabled={isInReview ? false : isDisabled}
							// defaultValue={ratingId}
							/>
						</div>
					</div>

					{/* Rating field */}
					<div className="mb-5 row ">
						<div className="col-md-2">
							<span className="span-text">Level Of Action</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<NCSelect
								options={categoryLevelsList}
								name={`rating`}
								isClearable={true}
								isSearchable={true}
								isMulti={false}
								requiredOptions={requiredOptions}
								control={control}
								errors={errors}
								showDescription={true}
								isDisabled={isInReview ? false : isDisabled}
								defaultValue={caseRatingObject?.id}
								onChange={_handleOnChangeRating}
							/>
						</div>
					</div>

					{/* Date of Submission */}
					{caseData &&
						<div className="mb-5 row">
							<div className="col-md-2">
								<span className="span-text">Date of Submission</span>
								{/* <span className="required-mark">*</span> */}
							</div>
							<div className="col-md-10 ncc-pr-0">
								<div className="row">
									<div className="col-md-6">
										<NCDatePicker
											name={`submission_date`}
											control={control}
											register={register}
											errors={errors}
											requiredOptions={requiredOptions}
											className={`main-input-style ncc-pl-px-10`}
											minDate={moment().toDate()}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						</div>}

					{/* Date of Submission */}
					{caseData && caseData.status === 'approved' && viewAction !== 'edit' &&
						<div className="mb-5 row">
							<div className="col-md-2">
								<span className="span-text">Date of Confirmation</span>
								{/* <span className="required-mark">*</span> */}
							</div>
							<div className="col-md-10 ncc-pr-0">
								<div className="row">
									<div className="col-md-6">
										<NCDatePicker
											name={`confirmation_date`}
											control={control}
											register={register}
											errors={errors}
											requiredOptions={requiredOptions}
											className={`main-input-style ncc-pl-px-10`}
											minDate={moment().toDate()}
											disabled={isDisabled}
										/>
									</div>
								</div>
							</div>
						</div>}

					{/* Supporting Docs field */}
					<div className="mb-5 row">
						<div className="col-md-2">
							<span className="span-text">
								Supporting Documents <br /> (if any)
							</span>
							{/* <span className="required-mark">*</span> */}
						</div>
						<div className="row col-md-10">
							{/* Files */}
							<div className="col-md-4">
								<NCFileInput
									name={`documents`}
									errors={errors}
									getValues={getValues}
									setValue={setValue}
									// requiredOptions={caseData ? {} : requiredOptions}
									register={register}
									containerClassName={`dfl-r-jstrt al-ctr gk-pr-2 gk-pl-2`}
									wrapperClassName={`gk-fw-600 text-deco-underline cursor-pointer w-100`}
									files={caseData?.documents}
									isDisabled={isDisabled}
									type='document'
								/>
							</div>
							{/* Files */}
							<div className="col-md-2">
								<span className="text-right span-text">
									Photo
									<span className="required-mark">*</span>
								</span>
							</div>

							{/* Photo */}
							<div className="col-md-4">
								<NCFileInput
									name={`photos`}
									errors={caseData?.photos ? {} : errors}
									getValues={getValues}
									setValue={setValue}
									// register={register}
									register={caseData?.photos && viewOnly ? () => { } : register}
									requiredOptions={viewAction === 'edit' || caseData?.photos?.length > 0 ? notRequiredOptions : requiredOptions}
									// requiredOptions={requiredOptions}
									containerClassName={`dfl-r-jstrt al-ctr gk-pr-2 gk-pl-2`}
									wrapperClassName={`gk-fw-600 text-deco-underline cursor-pointer w-100`}
									files={caseData?.photos}
									isDisabled={isDisabled}
								/>
							</div>
						</div>
					</div>



					{/* Case Details Field */}
					<div className="mb-5 row">
						<div className="col-md-2">
							<span className="span-text">Case Details</span>
							<span className="required-mark">*</span>
						</div>
						<div id="case-desc" className={`col-md-10 ncc-pr-0`}>
							{!isDisabled ?
								<>
									<Controller
										name="description"
										rules={requiredOptions}
										control={control}
										render={({ field }) => (
											<FormEditor
												onEditorChange={(v) => field.onChange(v)}
												defaultValue={field.value}
											/>
										)}
									/>
									{errors.description && (
										<div className="feedback-invalid">
											{errors.description.message}
										</div>
									)}
								</>
								:
								<div dangerouslySetInnerHTML={{ __html: caseData?.description }}></div>
							}
						</div>
					</div>

					{/* #48072 - NRC-175 - [Enhancement] - Submit case - Approver field */}
					<div className="mb-2 row">
						<div className="col-md-2">
							<span className="span-text">Approver</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-10 ncc-pr-0">
							<NCSelect
								options={approversList}
								name={`approver_id`}
								isClearable={true}
								isSearchable={true}
								isMulti={false}
								requiredOptions={requiredOptions}
								control={control}
								errors={errors}
								isDisabled={isDisabled}
							/>
						</div>
					</div>
				</div>
				{/* End Section */}

				{/* Section */}

				{/* Set Review Date Fields */}
				<div className="pb-2 section">
					<div className="mb-2">
						<span className="span-text">
							<b>Review Period</b>
						</span>
					</div>
					<div className="mb-5 row">
						<div className="col-md-2">
							<span className="span-text">Set Review Date</span>
							<span className="required-mark">*</span>
						</div>
						<div className="col-md-5">
							<NCSelect
								onChange={handleChangeReviewPeriod}
								options={reviewPeriods}
								name={`review_period`}
								isClearable={true}
								isSearchable={true}
								isMulti={false}
								requiredOptions={requiredOptions}
								control={control}
								errors={errors}
								isDisabled={reviewPeriodDisable}
							/>
						</div>

						<div className="col-md-5 ncc-pr-0">
							<div className="row">
								<div className="cursor-not-allowed col-md-2 d-flex justify-content-center" onClick={() => handleFocusReviewDate()}>
									<CalendarSvg />
								</div>
								<div className="col-md-10">
									<NCDatePicker
										// disabled={isDisabled}
										disabled={true}
										name={`review_date`}
										control={control}
										register={register}
										errors={errors}
										requiredOptions={requiredOptions}
										className={`main-input-style ncc-pl-px-10 cursor-not-allowed`}
										minDate={moment().toDate()}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Review Remark */}
				{(isInReview || showReviewSections) &&
					<div className="mb-5 row">
						<div className="col-md-2">
							<span className="span-text">Review Remark</span>
							<span className="required-mark">*</span>
						</div>
						<div id="case-desc" className={`col-md-10 ncc-pr-0`}>
							{isInReview ?
								<>
									<Controller
										name="review_remark"
										rules={requiredOptions}
										control={control}
										render={({ field }) => (
											<FormEditor
												onEditorChange={(v) => {
													field.onChange(v)
													handleChangeReviewRemark(v)
												}}
												defaultValue={field.value}
											/>
										)}
									/>
									{errors.description && (
										<div className="feedback-invalid">
											{errors.description.message}
										</div>
									)}
								</>
								:
								(
									<>
										{allRemarksList?.review_remark?.length > 0 ?
											<div dangerouslySetInnerHTML={{ __html: allRemarksList.review_remark[allRemarksList.review_remark.length - 1].remark_content }}></div>
											:
											'N/A'
										}
									</>
								)
							}
						</div>
					</div>
				}

				{

					createNewCase === false ?

						<>
							{/* Approval Remark Field */}
							{
								(!showDischargeButton && (caseData?.approval_remark.length > 0 || (!caseData?.approval_remark?.remark_content && (commonStore?.currentRole === 'Approver' || commonStore?.currentRole === 'Admin')) || (!(caseData?.approval_remark.length > 0) && canEditApprovalRemark))) ?
									<>
										<div className="mb-2 row">
											<div className="col-md-2">
												<span className="span-text">Approval Remark</span>
												<span className="required-mark">*</span>
											</div>
											<div id="case-desc" className={`col-md-10 ncc-pr-0`}>
												{canEditApprovalRemark ?
													(<>
														<Controller
															name="approval_remark"
															rules={requiredOptions}
															control={control}
															render={({ field }) => (
																<FormEditor
																	onEditorChange={(v) => {
																		field.onChange(v)
																		handleChangeApprovalRemark(v)
																	}}
																	defaultValue={field.value}
																/>
															)}
														/>
														{errors.description && (
															<div className="feedback-invalid">
																{errors.description.message}
															</div>
														)}
													</>)
													:
													(
														<>
															{allRemarksList?.approval_remark?.length > 0 ?
																<div dangerouslySetInnerHTML={{ __html: allRemarksList.approval_remark[allRemarksList.approval_remark.length - 1].remark_content }}></div>
																:
																'N/A'
															}
														</>
													)
												}
											</div>
										</div>
									</> : ''
							}



							{/* Admin Remark Field */}
							{/* {
								(caseData?.admin_remark.length > 0 || (!caseData?.admin_remark && commonStore?.currentRole === 'Admin') || (!(caseData?.admin_remark.length > 0) && canEditAdmimRemark)) ?
									<>
										<div className="mb-2 row">
											<div className="col-md-2">
												<span className="span-text">Admin Remark</span>
												<span className="required-mark">*</span>
											</div>
											<div id="case-desc" className={`col-md-10 ncc-pr-0`}>
												{canEditAdmimRemark ?
													(<>
														<Controller
															name="admin_remark"
															rules={requiredOptions}
															control={control}
															render={({ field }) => (
																<FormEditor
																	onEditorChange={(v) => {
																		field.onChange(v)
																		handleChangeAdminRemark(v)
																	}}
																	defaultValue={field.value}
																/>
															)}
														/>
														{errors.description && (
															<div className="feedback-invalid">
																{errors.description.message}
															</div>
														)}
													</>)
													:
													(
														<>
															{
																allRemarksList?.admin_remark && allRemarksList?.admin_remark.length > 0 ?
																	<div dangerouslySetInnerHTML={{ __html: allRemarksList.admin_remark[allRemarksList.admin_remark.length - 1].remark_content }}></div>
																	:
																	'N/A'
															}
														</>
													)
												}
											</div>
										</div>
									</> :
									''
							} */}

						</> :
						''
				}


				{/* End Section */}
				{/* Remark Table */}
				{showTable && allRemarksList &&
					<div className="mb-2 row">
						<div className="col-md-2">
							<span className="span-text">Remark History</span>
						</div>
						<div id="case-desc" className={`col-md-10 ncc-pr-0`}>
							<table className="remark-table">
								<thead>
									<tr>
										{/* <th className="remark-table__title">Remark History</th> */}
										<th className="remark-table__title">Requestor</th>
										<th className="remark-table__title">Approver</th>
										{/* <th className="remark-table__title">Admin</th> */}
									</tr>
								</thead>
								<tbody>
									{maxRemarkLength > 0 && Array(maxRemarkLength)
										.fill()
										.map((item, index) => (
											<tr key={_.uniqueId('ki_')}>
												{allRemarksList?.review_remark?.length > 0 ?
													<td>
														{allRemarksList.review_remark && allRemarksList.review_remark[index]?.remark_content && (
															<span className="remark-table__date">{moment(allRemarksList.review_remark[index].updatedAt).format('DD/MM/YYYY HH:mm')}</span>
														)}
														{allRemarksList.review_remark && allRemarksList.review_remark[index]?.remark_content && (
															<div className="remark-data-content" dangerouslySetInnerHTML={{ __html: allRemarksList.review_remark[index].remark_content }}></div>
														)}
													</td>
													:
													<td></td>
												}
												{allRemarksList?.approval_remark?.length > 0 ?
													<td>
														{allRemarksList.approval_remark && allRemarksList.approval_remark[index]?.remark_content && (
															<span className="remark-table__date">{moment(allRemarksList.approval_remark[index].updatedAt).format('DD/MM/YYYY HH:mm')}</span>
														)}
														{allRemarksList.approval_remark && allRemarksList.approval_remark[index]?.remark_content && (
															<div className="remark-data-content" dangerouslySetInnerHTML={{ __html: allRemarksList.approval_remark[index].remark_content }}></div>
														)}
													</td>
													:
													<td></td>
												}
												{/* {allRemarksList?.admin_remark?.length > 0 ?
													<td>
														{allRemarksList.admin_remark[index] && allRemarksList.admin_remark[index]?.remark_content && (
															<span className="remark-table__date">{moment(allRemarksList.admin_remark[index].updatedAt).format('DD/MM/YYYY HH:mm')}</span>
														)}
														{allRemarksList.admin_remark[index] && allRemarksList.admin_remark[index]?.remark_content && (
															<div className="remark-data-content" dangerouslySetInnerHTML={{ __html: allRemarksList.admin_remark[index].remark_content }}></div>
														)}
													</td>
													:
													<td></td>
												} */}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				}
				{/* End Remark Table */}

				<div className="d-flex justify-content-center">
					{createNewCase ?
						<>
							<Link
								to={'/'}
								className="m-3 text-center btn otp-button-transparent"
							>
								Cancel
							</Link>
							{((commonStore.currentRole === 'Requestor' || commonStore.currentRole === 'Admin') && !caseData) &&
								<button
									key={_.uniqueId('j1_')}
									className="m-3 btn btn-success"
									onClick={handleSubmit(data => doSubmit(data, 'submit', caseData))}
									type="button"
								// disabled={errorDupPhone}
								>
									Submit &nbsp;
									<Loading loading={loading} />
								</button>

							}
						</>
						:
						''
					}
					{
						viewOnly !== true ?
							<>
								{caseData && caseData?.case_action.length > 0 && caseData?.case_action.map((action, index) => {
									return (
										<div key={`${action?.cta_action}`}>
											{action?.cta_action === 'save' ?
												<button className={`btn button-action button-${action?.cta_action} m-3`} onClick={handleSubmit((data) => doSubmit(data, 'save', caseData))}> {action.cta_label}
													<Loading loading={loading} />
												</button>
												:
												<button
													disabled={
														(() => {
															if (action?.cta_action === 'extend') {
																return isDisabledExtendCTA === true;
															} else if (action?.cta_action === 'discharge') {
																return isDisabledDischargeCTA === true;
															} else if (
																action?.cta_action === 'endorse' ||
																action?.cta_action === 'reject' ||
																action?.cta_action === 'approve'
															) {
																return isAllowToDoActions === false;
															} else {
																return false;
															}
														})()
													}
													className={`btn m-3 button-action
												${(() => {
															switch (action?.cta_action) {
																case 'discharge':
																	if (isDisabledDischargeCTA === true) {
																		return 'button-not-allowed cursor-not-allowed';
																	} else {
																		return 'button-' + action?.cta_action;
																	}
																case 'extend':
																	if (isDisabledExtendCTA === true) {
																		return 'button-not-allowed cursor-not-allowed'
																	} else {
																		return 'button-' + action?.cta_action;
																	}
																case 'endorse':
																	if (isAllowToDoActions === false) {
																		return 'btn-secondary cursor-not-allowed'
																	} else {
																		return 'button-' + action?.cta_action;
																	}
																case 'reject':
																	if (isAllowToDoActions === false) {
																		return 'btn-secondary cursor-not-allowed'
																	} else {
																		return 'button-' + action?.cta_action;
																	}
																case 'delete':
																	return 'button-' + action?.cta_action;
																case 'approve':
																	if (isAllowToDoActions === false) {
																		return 'btn-secondary cursor-not-allowed'
																	} else {
																		return 'button-' + action?.cta_action;
																	}
																default:
																	return 'button-' + action?.cta_action;
															}
														})()
														}
												`}
													onClick={(e) => {
														switch (action?.cta_action) {
															case 'cancel':
																navigate('/')
																break;
															case 'approve':
																e.preventDefault();
																handleCaseAction('approved');
																break;
															case 'discharge':
																e.preventDefault();
																handleExtendDischarge('discharge');
																break;
															case 'extend':
																e.preventDefault()
																handleExtendDischarge('extend');
																break;
															case 'endorse':
																e.preventDefault()
																handleCaseAction('endorsed');
																break;
															case 'reject':
																e.preventDefault()
																handleCaseAction('rejected');
																break;
															case 'delete':
																e.preventDefault();
																handleDeleteCase('delete');
																break;
														}
													}}
												>{action.cta_label}
													{/* <Loading loading={ctaLoading} /> */}
												</button>
											}

										</div>
									)
								})
								}
							</>
							:
							<>
								{caseData && caseData?.case_action.length > 0 && caseData?.case_action.map((action, index) => {
									return (
										<div key={`${action?.cta_action}`}>
											{action?.cta_action === 'cancel' &&
												<button className={`btn button-${action?.cta_action} button-action m-3`}
													onClick={(e) => {
														switch (action?.cta_action) {
															case 'cancel':
																navigate('/')
														}
													}}
												>{action.cta_label}</button>
											}

										</div>
									)
								})
								}
							</>
					}

					<ModalApprove
						show={showApprove}
						toggle={() => setShowApprove(!showApprove)}
					/>

					<ModalEndorse
						show={showEndorse}
						toggle={() => setShowEndorse(!showEndorse)}
					/>

					<ModalReject
						show={showReject}
						toggle={() => setShowReject(!showReject)}
					/>

					<ModalDischarge
						show={showDischarge}
						toggle={() => setShowDischarge(!showDischarge)}
					/>

					<ModalExtend
						show={showExtend}
						toggle={() => setShowExtend(!showExtend)}
					/>

				</div>
			</div>
		</form>
	);
};

export default CaseForm;
